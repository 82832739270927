<template>
  <v-container>
    <div class="mt-10 text-center">ใส่รหัส PIN เพื่อเข้าสู่ระบบ</div>
    <vue-pincode
      class="mt-3"
      ref="pincodeInput"
      @pincode="login"
      propShowText="หากลืมรหัสให้ติดต่อผู้ดูแล"
    />
  </v-container>
</template>

<script>
import VuePincode from "@/components/PinCode/VuePincode";

export default {
  name: "Login",
  components: {
    VuePincode,
  },
  methods: {
    async login(pincode) {
      let parameter = {
        to: "login",
        method: "check_pin",
        code: pincode,
      };

      await this.$store.dispatch("auth/TransferData", parameter).then(
        (response) => {
          this.$router.push("/SelectPeriod");
        },
        (error) => {
          switch (error) {
            case "PIN_INCORRECT":
              this.$refs.pincodeInput.resetPincode();
              this.$alert("รหัสไม่ถูกต้อง.", "ผิดพลาด", "error").then(() => {});
              break;
            default:
              alert(error);
              break;
          }
        }
      );
    },
  },
};
</script>

<style scoped></style>
>
